import * as React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

import Styles from './index.module.css';
import Hanreiimg from '../../components/hanrei-img3';



const Table = props => (
<div>
    <div className={Styles.user}>
      <div className={Styles.description}>
        <h2 className={Styles.username}><span className={Styles.themename}>{props.themename}</span>{props.username}</h2>
        <p className={Styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
    <Link to={props.url}>
    </Link>
</div>
)




const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const About: React.FunctionComponent<AboutProps> = (props) => {
  const Data = useStaticQuery(graphql`
  query {
    avatar: file(absolutePath: { regex: "/about.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1770, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        author
        about
      }
    }
  }
`);

  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
  <div >

    {/* <div className={Styles.table}> */}
{/* <div className={Styles.item}> */}
      {/* <div className={Styles.img}> */}
        <Hanreiimg
          frontmatter={props.frontmatter}
        />
      {/* </div> */}
    {/* </div> */}

    {/* </div> */}

    </div>

  );
};
 
export default About;



